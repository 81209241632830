.gallery {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.gallery__item {
    padding: 5px;
    display: inline-block;
}

.gallery__item img {
    max-width: 100%;
    height: auto;
}

.lg-backdrop {
    transition: opacity 333ms ease-in 0s, background-color 333ms ease-in 0s;
}
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');

.link-phone{
    color: rgb(255, 255, 255);
        background-color: transparent;
        text-decoration: none;
}
